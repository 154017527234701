@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("font-files/Inter-Thin.woff2?v=3.11") format("woff2"), url("font-files/Inter-Thin.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("font-files/Inter-ThinItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-ThinItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("font-files/Inter-ExtraLight.woff2?v=3.11") format("woff2"), url("font-files/Inter-ExtraLight.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("font-files/Inter-ExtraLightItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-ExtraLightItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("font-files/Inter-Light.woff2?v=3.11") format("woff2"), url("font-files/Inter-Light.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("font-files/Inter-LightItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-LightItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("font-files/Inter-Regular.woff2?v=3.11") format("woff2"), url("font-files/Inter-Regular.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("font-files/Inter-Italic.woff2?v=3.11") format("woff2"), url("font-files/Inter-Italic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("font-files/Inter-Medium.woff2?v=3.11") format("woff2"), url("font-files/Inter-Medium.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("font-files/Inter-MediumItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-MediumItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("font-files/Inter-SemiBold.woff2?v=3.11") format("woff2"), url("font-files/Inter-SemiBold.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("font-files/Inter-SemiBoldItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-SemiBoldItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("font-files/Inter-Bold.woff2?v=3.11") format("woff2"), url("font-files/Inter-Bold.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("font-files/Inter-BoldItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-BoldItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("font-files/Inter-ExtraBold.woff2?v=3.11") format("woff2"), url("font-files/Inter-ExtraBold.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("font-files/Inter-ExtraBoldItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-ExtraBoldItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("font-files/Inter-Black.woff2?v=3.11") format("woff2"), url("font-files/Inter-Black.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("font-files/Inter-BlackItalic.woff2?v=3.11") format("woff2"), url("font-files/Inter-BlackItalic.woff?v=3.11") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  font-display: swap;
  src: url("font-files/Inter-roman.var.woff2?v=3.11") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: "Italic";
  font-display: swap;
  src: url("font-files/Inter-italic.var.woff2?v=3.11") format("woff2");
}

@font-face {
  font-family: Inter var alt;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  font-display: swap;
  src: url("font-files/Inter-roman.var.woff2?v=3.11") format("woff2");
}

@font-face {
  font-family: Inter var alt;
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: "Italic";
  font-display: swap;
  src: url("font-files/Inter-italic.var.woff2?v=3.11") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  src: url("font-files/Inter.var.woff2?v=3.11") format("woff2");
}

*, :before, :after {
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-text-size-adjust: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input:not([type="radio"]):not([type="checkbox"]), textarea, select {
  -webkit-appearance: none;
}

mark {
  color: inherit;
  background-color: #0000;
  font-weight: bold;
}

@font-face {
  font-family: orty;
  src: url("orty.c81ea8a0.woff2");
}

html, body {
  margin: 0;
}

body {
  background: #222;
}

canvas {
  display: block;
}

.title {
  color: #222;
  text-transform: lowercase;
  text-align: center;
  width: 100%;
  font-family: orty;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  color: #6dffa9;
  width: 800px;
  max-width: 95%;
  margin: 0 auto;
  font-family: Roboto Mono, monospace;
}

.content section {
  width: 100%;
}

.content h1 {
  font-weight: bold;
}

.content p li, .content ul li {
  margin-bottom: 10px;
}

.content p a, .content ul a {
  color: #6dffa9;
  text-decoration: none;
}

footer {
  text-align: center;
  background: #6dffa9;
  height: 200vh;
  font-family: Roboto Mono, monospace;
}

footer a {
  color: #222;
  text-decoration: none;
}

footer p {
  color: #222;
  margin-top: 30px;
}

.bottom {
  color: #6dffa9;
  margin: 0 auto;
  font-family: Roboto Mono, monospace;
}

@media screen and (width >= 801px) {
  .title {
    font-size: 240px;
  }

  .title p {
    margin: -100px 0;
  }

  .content section {
    margin-bottom: 100px;
  }

  .content h1 {
    margin-bottom: 20px;
    font-size: 72px;
  }

  .content p, .content ul {
    font-size: 21px;
    line-height: 34px;
  }

  .content p a, .content ul a {
    border-bottom: 2px solid coral;
  }

  footer {
    padding: 100px 0;
  }

  footer a {
    border-bottom: 4px solid #222;
    font-size: 42px;
  }

  footer p {
    font-size: 21px;
  }

  .bottom {
    width: 80%;
    font-size: 50px;
  }
}

@media screen and (width <= 800px) {
  .title {
    font-size: 135px;
  }

  .title p {
    margin: -60px 0;
  }

  .content section {
    margin-bottom: 50px;
  }

  .content h1 {
    margin-bottom: 20px;
    font-size: 42px;
  }

  .content p, .content ul {
    font-size: 16px;
    line-height: 24px;
  }

  .content p a, .content ul a {
    border-bottom: 2px solid coral;
  }

  footer {
    padding: 40px 0;
  }

  footer a {
    border-bottom: 2px solid #222;
    font-size: 24px;
  }

  footer p {
    font-size: 16px;
  }

  .bottom {
    width: 95%;
    font-size: 21px;
  }
}

/*# sourceMappingURL=index.e8aba8bd.css.map */
