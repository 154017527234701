@import "./assets/inter.css"
@import "./reset.styl"

verdino = rgb(109, 255, 169)
grigino = #222
robby = 'Roboto Mono', monospace   

mobile-copy = 16px

@font-face
    font-family orty
    src url("assets/orty.woff2")  

html, body
    margin 0

body
    background grigino

canvas
    display block

.title    
    font-family orty
    color grigino
    text-transform lowercase    
    width 100%
    text-align center
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)    

.content        
    color verdino
    font-family robby
    width 800px
    max-width 95%
    margin 0 auto
    section        
        width 100%
    h1                            
        font-weight: bold         
    p, ul             
        li
            margin-bottom 10px
        a            
            color verdino
            text-decoration none

footer 
    height 200vh
    font-family robby
    text-align center    
    background verdino
    // border-top 3px dashed verdino
    a        
        color grigino
        text-decoration none
    p
        color grigino
        margin-top 30px

.bottom    
    color verdino
    font-family robby    
    margin 0 auto    

// @keyframes float 
//     from 
//         transform ro

//desktop
@media screen and (min-width: 801px)
    .title
        font-size 240px
        p
            margin -100px 0
    
    .content
        section
            margin-bottom 100px
        h1        
            font-size 72px    
            margin-bottom 20px 
        p, ul     
            font-size 21px
            line-height 34px    
            a                
                border-bottom 2px solid coral
    footer
        padding 100px 0
        a
            font-size 42px
            border-bottom 4px solid grigino  
        p
            font-size 21px 
    .bottom        
        font-size 50px
        width 80%

//mobile
@media screen and (max-width: 800px)
    .title
        font-size 135px
        p
            margin -60px 0
    .content
        section
            margin-bottom 50px
        h1        
            font-size 42px   
            margin-bottom 20px     
        p, ul     
            font-size mobile-copy
            line-height 24px  
            a                
                border-bottom 2px solid coral
    footer
        padding 40px 0
        a
            font-size 24px
            border-bottom 2px solid grigino 
        p
            font-size mobile-copy
    .bottom        
        font-size 21px
        width 95%
            
